import { useEffect, useState } from 'react';
import axios from 'axios'
import './App.css';
import {QRCodeSVG} from'qrcode.react'

function App() {
  let newData = {vehicleModelId:'',vehicleColorId:'', id:'' };
  let typeName = ''
  let colorName = ''
  let latID = ''
  let searchid = ''
  const [qty, setqty]= useState(1)
  const [code, setCode] = useState('');
  const [typeId, setTypeId] = useState('');
  const [colorId, setColorId] = useState('');
  const [vehicleColor, setVehicleColor] = useState('');
  const [qrval, setQrVal] = useState('');
  const [latestID, setLatestID] = useState(0);

  useEffect(()=>{
    axios.get(`https://ecgobikeid.com/pabrik/VColor?vehicleModelId=${typeId}`).then(((response) =>{
        console.log(response.data);
        setVehicleColor(response.data)
      })
      )
  },[typeId])

  useEffect(()=>{
    getlatestID()
   },[])

  useEffect(()=>{
    newData = ({vehicleModelId: typeId, vehicleColorId:colorId, id:latestID});
    let jsondata = JSON.stringify(newData); 
    setQrVal(jsondata)
    console.log(jsondata)
  },[latestID])

  

  function handleChange(e) {
    let vehicleTypeId = parseInt(document.getElementById('modelList').value);
    setTypeId(vehicleTypeId)
  }

  function handleChange2(e){
    let vehicleColorId = parseInt(document.getElementById('colorList').value);
    setColorId(vehicleColorId);
  }

  function getlatestID(){
    axios.get(`https://ecgobikeid.com/pabrik/latestid`).then((response => {
      setLatestID(response.data.data.id)
      console.log(response.data.data.id);
    }))
    return(
      console.log(colorId)
    )
  }
  // for (let i = 0; i<= qty; i++){
  //   latID = latestID+i
  // }

  function handleSubmit(e){
    e.preventDefault(); 
    // newData = ({vehicleModelId: typeId, vehicleColorId:colorId, id:parseInt(latestID)});
     
    let postData = ({vehicleColorId:colorId, qty:1, vehicleModelId:typeId})
    
    axios.post(`https://ecgobikeid.com/pabrik/create`, postData)
    .then((res)=>{
      getlatestID()
    })
     
    
  }
  

function handleSubmit2 () {
    
      searchid = document.getElementById('search').value
      axios.get(`https://ecgobikeid.com/pabrik/vehicle/${searchid}`).then((response => {
      newData = JSON.stringify(response.data.data)
      setQrVal(newData)
      console.log(response.data.data.code)
      setCode(response.data.data.code)
    }))
    
  }
    
  
  if( typeId == '2'){
    typeName = 'ECGO 3'
  } else if (typeId == '3') {
    typeName = 'ECGO 5'
  }
 console.log(typeName)
  if (colorId == '7'){
    colorName = 'SRC'
  } if (colorId == '6'){
    colorName = 'EBY'
  } if (colorId == '8'){
    colorName = 'SCB'
  } if (colorId == '9'){
    colorName = 'ETG'
  } if (colorId == '10'){
    colorName = 'RED'
  } if (colorId == '11'){
    colorName = 'WHI'
  } if (colorId == '12'){
    colorName = 'BLK'
  }
  console.log(colorName)
  let textData = code
  let textData2 = `${typeName}-${colorName}-${latestID}`


  return (
    <div className="App">
      <div className='left'>
        <QRCodeSVG value={qrval}/>

        
        <a>{textData2}</a>
       <a>{textData}</a>

      </div>
      <div className='right'>
        <h1>ECGO BARCODE GENERATOR</h1>
        <form style={{display:'flex', flexDirection:'column', width:'50%', marginTop:'10vh'}}>
          <select id='modelList' onChange={handleChange} >
            <option>Please Select Vehicle Type</option>
            <option value='1'>ECGO 2</option>
            <option value='2'>ECGO 3</option>
            <option value='3'>ECGO 5</option>
          </select>
          <br/>
       
          {vehicleColor ? 
            <select id='colorList' onChange={handleChange2}>
                    <option>Select Vehicle Color</option>
                  {vehicleColor.data.map(color => (
                    <option value={color.id} key={color.id}>{color.name}</option>
                  ))
                  }
            </select>
              : 
              <a>Please Select Vehicle Model First</a>
          }
          <br/>
           
            <button onClick={handleSubmit} style={{marginTop:'15px'}}>Print</button>
            
        </form>

        <input id='search' className='idsearch' type='number' placeholder='Search Vehicle ID' />
        <button onClick={handleSubmit2}>Search</button>
        </div>

        
    </div>
  );
}

export default App;
